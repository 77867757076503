/**
 * Generate a random integer between the given values. Adapted from: https://stackoverflow.com/a/1527820.
 *
 * @param {int} min Minimum number
 * @param {int} max Maximum number
 *
 * @return {int} Random integer within given range.
 */
export default function randomInt( min = 0, max = 999 )
{
	// Make sure we have whole numbers
    min = Math.ceil( min );
	max = Math.floor( max );

    return Math.floor(
		Math.random() * ( max - min + 1 )
	) + min;
}
