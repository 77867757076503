/**
 * API-request helper class.
 */



/**
 * Module imports.
 */
import { debug }                  from 'app/app.jsx';
import { fetch as fetchPolyfill } from 'whatwg-fetch';
import retry                      from 'async-retry';



/**
 * Module constants.
 */
export const BASEROUTE = '/rest/v2/';



/**
 * Main class for the module.
 */
export class API
{
	/**
	 * Member variables.
	 */
	baseURL         = '/';
	data            = {};
	decodedResponse = {};
	endpoint        = '';
	entity          = '';
	expectPlainText = false;
	parameters      = {};
	reject          = () => {};
	resolve         = () => {};
	response        = {};



	/**
	 * Class constructor.
	 */
	constructor( args )
	{
		// Set base URL and endpoint
		this.baseURL  = args.baseURL;
		this.endpoint = args.endpoint;

		// Default values
		if ( 'object' !== typeof args )
		{
			args = {};
		}

		if ( args.data )
		{
			this.parameters = args.data;
		}

		if ( 'string' === typeof args.entity )
		{
			this.entity = args.entity;
		}
		else if ( 'number' === typeof args.entity )
		{
			this.entity = args.entity.toString();
		}

		if ( args.expectPlainText )
		{
			this.expectPlainText = args.expectPlainText;
		}
	}



	/**
	 * Decodes the API response.
	 *
	 * @param  {object} response API-reponse.
	 *
	 * @return {object} API-response with decoded data.
	 */
	decodeResponse( response )
	{
		// Did we expext a plaintext response
		if ( 'boolean' === typeof this.expectPlainText && true === this.expectPlainText )
		{
			// Decode response as text
			this.decodedResponse = response.text();
		}
		else
		{
			// Decode response as JSON
			this.decodedResponse = response.json();
		}
	}



	/**
	 * Do the actual request.
	 */
	async doRequest()
	{
		// Set the basic request configuration
		const requestConfig =
		{
			credentials   : 'same-origin',
			'Content-Type': 'application/json',
			method        : 'GET',
			headers       :
			{
				'Accept': 'application/json',
			},
		};

		// Run request through `async-retry`
		this.response = await retry(
			async ( bail ) =>
			{
				// Issue a warning of slow network activity after 5 seconds
				const slowNetworkTimeout = setTimeout(
					() =>
					{
						debug( 'Request is taking longer than expected...' );
					},
					5000
				);

				let route = this.baseURL;

				// If base route starts with slash (currently superfluous but future proofs the handling of this)
				if ( '/' === BASEROUTE[ 0 ] )
				{
					route += BASEROUTE.substring( 1 );
				}
				else
				{
					route += BASEROUTE;
				}

				// Add endpoint
				route += this.endpoint;

				route += '/' + this.entity;

				// First parameter
				let first = true;

				// Loop paramaters
				for ( const param in this.parameters )
				{
					// First parameter
					if ( true === first )
					{
						route += '?';
					}
					// Not first parameter
					else
					{
						route += '&';
					}

					// Append actual parameter info
					route += param + '=' + this.parameters[ param ];

					// No longer first parameter
					first = false;
				}

				// Query the API
				const response = await fetchPolyfill(
					route,
					requestConfig
				);

				// If we get any status where the server responded properly, but which might be seen as an "error" of sorts (unathorized, requires login, wasn't even found, was found but was missing data, etc.)
				if ( 200 === response.status || 400 === response.status || 401 === response.status || 403 === response.status || 404 === response.status || 500 === response.status )
				{
					// Clear the request timeout
					clearTimeout( slowNetworkTimeout );

					// Decode the response
					this.decodeResponse( response );

					return response;
				}
				// Didn't complete in any meaningful way
				else
				{
					// Throw a random error. The message doesn't matter as we run a separate HTTP request handler but the throwing DOES. Without it no automatic retries will be made.
					// IMPORTANT NOTE: This MUST contain something as just `throw '';` leads to an error. Why? No idea. Might even be our build chain rationslising `throw '';` to just `throw;` but I really don't know. So just don't change that specific part, OK?
					throw 'API: Network error occured when running request.';
				}
			},
			{
				retries: 5,
			}
		);

		// Check if request went all right
		if ( 200 === this.response.status )
		{
			// Resolve the promise and return the response
			this.resolve( this.decodedResponse );
		}
		else
		{
			debug( 'A request didn\'t finish properly.' );
		}
	}
}



/**
 * Export a function dedicated to running requests.
 *
 * @param  {object} args Function arguments.
 *
 * @return {Promise}  Promise returning JSON when request finishes
 */
export function request( args = {} )
{
	// Create new promise and return it
	return new Promise(
		( resolve, reject ) =>
		{
			// Instantiate a new object
			const APIRequest = new API( args );

			// Set promise variables
			APIRequest.resolve = resolve;
			APIRequest.reject  = reject;

			// Run the request
			APIRequest.doRequest();
		}
	);
}
