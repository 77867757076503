/**
 * Module for adding possesive 's'-s.
 *
 * @param {array} input Array of items to add to. Must be flat and contain strings.
 *
 * @return {array} Array with possesive pronoun added.
 */
export default function possesive( input )
{
	// If it's a string
	if ( 'object' !== typeof input )
	{
		input =
		[
			input,
		];
	}

	// Return string
	return input.map(
		( item ) =>
		{
			// If it doesn't end with an 's'
			if ( -1 === item.indexOf( 's', item.length - 1 ) )
			{
				return item + 's';
			}
			else
			{
				return item;
			}
		}
	);
}
