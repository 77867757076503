/**
 * The main module for rendering the app.
 */




/**
 * Module imports.
 */
import BEMHelper from 'react-bem-helper';
import React     from 'react';
import Sidebar   from 'app/modules/sidebar/sidebar.jsx';



/**
 * Style imports.
 */
import './fonts.scss';
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import './app.scss';



/**
 * Module constants.
 */
export const APP_NAME     = 'Matochmat';
export const APP_SITE_URL = 'https://www.matochmat.se/';
const BEM = new BEMHelper(
	{
		name          : 'matochmat',
		outputIsString: true,
	}
);



/**
 * Debugging function to be exported and used throughout the system.
 *
 * @param {*} message Message to print.
 */
export function debug( ...information )
{
	// Check that we have the relevant console actions
	if ( 'undefined' !== typeof console && 'function' === typeof console.log )
	{
		console.log( APP_NAME + ':', ...information );
	}
}



/**
 * Main & default module for the class.
 */
export default class App extends React.Component
{
	/**
	 * Member variables.
	 */
	layouts =
	{
		sidebar: ( props ) => <Sidebar { ...props } />,
		slider : ( props ) => <div>Slider module (not yet developed)</div>,
	};
	state =
	{
		// Default to using live site for base URL
		baseURL      : APP_SITE_URL,
		contentLoaded: false,
		// Default to sidebar
		layout       : 'sidebar',
		type         : 'randomized-restaurants',
	};



	/**
	 * Class constructor.
	 *
	 * @param {*} props Properties derived from React.
	 */
	constructor( props )
	{
		// Run parent constructor
		super( props );

		// Reference the root element (locally, as we're not supposed to be touching it in post)
		const rootElement = document.getElementById( 'matochmat-root' );

		// List of attributes we accept and the names we'll use internally
		const attributes =
		{
			'data-cities':
			{
				canBeCommaSeparated: true,
				forceArray         : true,
				internalName       : 'cities',
			},
			'data-base-url':
			{
				internalName: 'baseURL',
			},
			'data-layout':
			{
				internalName: 'layout',
			},
			'data-restaurant':
			{
				internalName: 'restaurant',
			},
			'data-type':
			{
				internalName: 'type',
			},
		};

		// Loop attributes
		Object.keys( attributes ).map(
			( attribute ) =>
			{
				// Get value from attribute
				let value = rootElement.getAttribute( attribute );

				// If we got valid input
				if ( null !== value && 'string' === typeof value && 0 < value.length )
				{
					// Parse as JSON if possible
					try
					{
						value = JSON.parse( value );
					}
					// Otherwise default to what it is
					catch( err )
					{
						// If it's a value that can be comma separated and it contains a comma
						if ( true === attributes[ attribute ].canBeCommaSeparated && -1 !== value.indexOf( ',' ) )
						{
							// Split on commas
							value = value.split( ',' ).map(
								( value ) =>
								{
									// Check if it's a number and parse if it is (we DON'T run our little JSON trick here as that would possible parse nested JSON which we don't want to be the default)
									if ( ! isNaN( value ) )
									{
										value = parseFloat( value );
									}

									return value;
								}
							);
						}
					}

					// Some values we want to force to arrays no matter what the user enters
					if
					(
						true === attributes[ attribute ].forceArray
						&&
						(
							'object' !== typeof value
							||
							! Array.isArray( value )
						)
					)
					{
						// make it into an array and add it as the sole value
						value =
						[
							value,
						];
					}

					// Assign to state
					this.state[ attributes[ attribute ].internalName ] = value;
				}
			}
		);
	}



	/**
	 * Handler for when content has loaded.
	 */
	handleContentLoaded = () =>
	{
		setTimeout(
			() =>
			{
				this.setState(
					{
						contentLoaded: true,
					}
				);
			},
			300
		);
	}



	/**
	 * Renders the component.
	 */
	render()
	{
		// Break out variables from state for easier reference
		const { baseURL, cities, contentLoaded, layout, restaurant, type } = this.state;

		// If we have a matching layout
		if ( -1 !== Object.keys( this.layouts ).indexOf( layout ) )
		{
			// Set layout to a variable as the square brackets throw errors within component names
			const Layout = this.layouts[ layout ];

			return (
				<div
					className={
						BEM(
							{
								modifiers:
								{
									'content-loaded'   : contentLoaded,
									'hide-until-loaded': 'sidebar' === layout,
								}
							}
						)
					}
				>
					<Layout
						baseURL={ baseURL }
						cities={ cities }
						onContentLoaded={ this.handleContentLoaded }
						restaurant={ restaurant }
						type={ type }
					/>
				</div>
			);
		}
		// No matching layout
		else
		{
			debug( 'No matching layout found. Provided layout was:', layout );

			return null;
		}
	}
}
