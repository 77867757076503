/**
 * Module for creating conjunction from array elements.
 */
export default function conjunction( values = [], conjunction = 'och' )
{
	// Count items to prematurely micro-optimize
	const items = values.length;

	// Start with an empty string
	let str = '';

	// If we had any items at all
	if ( 0 < items )
	{
		// Always add first item directly
		str += values[ 0 ];

		// If it's more than a single item
		if ( 1 < items )
		{
			// Loop elements
			for ( let i = 1; i < items; i++ )
			{
				// Is it the last one?
				if ( i === ( items - 1 ) )
				{
					str += ' ' + conjunction;
				}
				// Not last
				else
				{
					str += ',';
				}

				// Append value
				str += ' ' + values[ i ];
			}
		}
	}

	// Return string
	return str;
}
