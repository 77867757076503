/**
 * The main entry point for the integration.
 */



/**
 * Module imports.
 */
import '@babel/polyfill/noConflict';
import App, { debug } from 'app/app.jsx';
import React          from 'react';
import ReactDOM       from 'react-dom';



/**
 * Runtime.
 */

// Wrap everything in an IIFE just to be on the safe side
(
	() =>
	{
		// Wrap in a try-catch block so we can use our own debugging function for all errors
		try
		{
			// Render the app
			ReactDOM.render(
				<App />,
				document.getElementById( 'matochmat-root' )
			);
		}
		catch ( err )
		{
			debug( err );
		}
	}
)();
